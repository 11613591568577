import { useCallback, useEffect, useRef, useState } from 'react'
import { useToggle } from 'shared/hooks'
import { useNavigate } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'
import { FormProvider, useForm } from 'react-hook-form'

import { Column } from 'react-table'
import { maskedDateTime } from 'utilities/date'

import { Button, ComboboxItem, Table, TableRef } from 'components'

import { PatrolDriver } from 'services/patrol'
import { PatrolFiltersSchema } from '../types'
import { Pagination } from 'services/types'
import { PatrolResponse } from 'services/patrol/types'
import { AggregatedAccount } from 'services/account/types'
import { patrolFiltersSchema } from '../schemas'
import { DeletePatrolModal } from '../components'

import { translatedDayOfWork } from 'domains/customer/utilities/dayOfWork'
import { useGetAccounts } from 'services/account'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { parseDataToComboboxV2 } from 'utilities/combobox'

import { ReactComponent as InspectIcon } from 'assets/svg/listInspectIcon.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg'

import styles from './styles.module.scss'
import { groupIntoBusinessDays } from 'domains/customer/screens/Patrol/utilities/schedules'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'
import { buildPath, paths } from 'routes'

const columns: Column<PatrolResponse>[] = [
  {
    id: 'name',
    Header: 'Nome',
    accessor: ({ name }) => name,
  },
  {
    id: 'account',
    Header: 'Conta',
    accessor: ({ account }) =>
      !!account && `${account?.code} - ${account?.name}`,
  },
  {
    id: 'startDate',
    Header: 'Data inicial',
    accessor: ({ startDate }) => maskedDateTime(startDate),
  },
  {
    id: 'endDate',
    Header: 'Data final',
    accessor: ({ endDate }) => maskedDateTime(endDate),
  },
  {
    id: 'schedules',
    Header: 'Escala semanal',
    accessor: ({ schedules }) => {
      const formattedSchedule = groupIntoBusinessDays(schedules)

      return formattedSchedule
        .map((schedule) => translatedDayOfWork[schedule.dayOfWork])
        .join(', ')
    },
  },
  {
    id: 'createdAt',
    Header: 'Cadastrado em',
    accessor: ({ createdAt }) => maskedDateTime(createdAt),
  },
]

const Patrol = () => {
  const { customer, account: accountContext } = useCustomerContext()
  const tableRef = useRef<TableRef>(null)
  const navigate = useNavigate()
  const patrolDelete = useToggle()

  const { getFilters, setFilters } =
    usePersistentFilters<PatrolFiltersSchema>('patrol')

  const [patrolToRemove, setPatrolToRemove] = useState('')

  const form = useForm<PatrolFiltersSchema>({
    resolver: joiResolver(patrolFiltersSchema),
    mode: 'onChange',
    defaultValues: getFilters(),
  })

  const { handleSubmit } = form

  const [accountFilter, setAccountFilter] = useState('')
  const [searching, setSeaching] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<AggregatedAccount>()

  const {
    data: accounts,
    isFetching: isFetchingAccounts,
    fetchNextPage: fetchNextAccountPage,
  } = useGetAccounts(customer?.id, { name: accountFilter })

  useEffect(() => {
    if (!selectedAccount && !searching && accountContext?.id) {
      const foundAccount = accounts?.find(
        (account) => account.id === accountContext?.id,
      )
      if (foundAccount) {
        setSelectedAccount(foundAccount)
        setFilters({ ...getFilters(), account: foundAccount })
      }
    }
  }, [
    accountContext,
    accounts,
    setFilters,
    getFilters,
    accountFilter,
    searching,
  ])

  const selectedAccountId = selectedAccount?.id

  const fetchPatrol = useCallback(
    async (pagination: Pagination) => {
      try {
        const patrol = await PatrolDriver.query({
          page: Math.round(pagination.offset / pagination.recordsPerPage),
          size: pagination.recordsPerPage,
          accountId: selectedAccountId || accountContext?.id,
        })
        return patrol
      } catch (error) {
        console.error(error)
      }
    },
    [accountContext, selectedAccountId],
  )

  useEffect(() => {
    tableRef.current?.handleFetchPage()
  }, [selectedAccountId])

  return (
    <>
      <div className={styles.container}>
        <DeletePatrolModal
          isVisible={patrolDelete.isVisible}
          onClose={() => {
            patrolDelete.hide()
            tableRef.current?.handleFetchPage()
          }}
          patrolId={patrolToRemove}
        />

        <FormProvider {...form}>
          <div className={styles.actions}>
            <form
              className={styles.inputsWrapper}
              onSubmit={handleSubmit(() => {
                tableRef.current?.handleFetchPage()
              })}
            >
              <div className={styles.innerWrapper}>
                <Combobox
                  label={{ text: 'Conta' }}
                  isLoading={isFetchingAccounts}
                  onEndReached={fetchNextAccountPage}
                  items={parseDataToComboboxV2(
                    accounts || [],
                    'aggregatedAccountName',
                  )}
                  onChange={(selected) => {
                    const accountSelected =
                      selected as ComboboxItem<AggregatedAccount>
                    setSelectedAccount(accountSelected.value)
                    const newFilters = {
                      ...getFilters(),
                      accountId: accountSelected.value.id,
                    }
                    setFilters(newFilters)
                  }}
                  value={
                    selectedAccount
                      ? {
                          label: 'aggregatedAccountName',
                          value: selectedAccount,
                        }
                      : undefined
                  }
                  onSearch={(text) => {
                    if (text === '') {
                      setSelectedAccount(undefined)
                    }
                    setSeaching(true)
                    setAccountFilter(text)
                  }}
                />
                <Button
                  id="add-patrol-button"
                  buttonTitle="Nova ocorrência periódica"
                  type="secondary"
                  icon={PlusIcon}
                  width="300px"
                  onClick={() => {
                    navigate(
                      buildPath({
                        path: paths.account.patrol.create,
                        params: {
                          accountId: accountContext?.id,
                        },
                      }),
                    )
                  }}
                />
              </div>
            </form>
          </div>

          <Table
            innerRef={tableRef}
            fetcher={fetchPatrol}
            resetPagination={false}
            columns={columns}
            paginated
            actions={[
              {
                label: 'Visualizar',
                Icon: InspectIcon,
                handler: (patrol) => {
                  if (patrol.id) {
                    navigate(
                      buildPath({
                        path: paths.account.patrol.update,
                        params: {
                          accountId: accountContext?.id,
                          patrolId: patrol.id,
                        },
                      }),
                    )
                  }
                },
              },
              {
                label: 'Excluir',
                Icon: TrashIcon,
                handler: (item) => {
                  setPatrolToRemove(item.id)
                  patrolDelete.show()
                },
              },
            ]}
          />
        </FormProvider>
      </div>
    </>
  )
}

export default Patrol
